import { Container, Grid, Typography, Button, Paper, makeStyles, Theme, createStyles } from '@material-ui/core';
import { ErrorIcon } from '../../../../icons/ErrorIcon';
import { QualityScore } from '../Quality';
import { useAppStateContext } from '../../../AppStateProvider/AppStateProvider';
import { ViewIcon } from '../../../../icons/ViewIcon';
import { WarningIcon } from '../../../../icons/WarningIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      float: 'left',
      [theme.breakpoints.down('md')]: {
        float: 'initial',
      },
    },
    heading: {
      position: 'relative',
    },
    paper: {
      padding: '1.2em',
      maxWidth: '400px',
      borderRadius: '8px',
      '& li': {
        '&:not(:last-child)': {
          marginBottom: '1.5em',
        },
      },
    },
    paperContainer: {
      float: 'right',
      marginRight: '1em',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        float: 'initial',
        justifyContent: 'center',
        margin: '0 0 2.5em 0',
      },
    },
    viewButton: {
      marginTop: '2em',
      '& svg': {
        position: 'relative',
        left: '-5px',
      },
    },
    confirmationButtons: {
      clear: 'left',
      [theme.breakpoints.down('md')]: {
        clear: 'initial',
        marginBottom: '2em',
      },
    },
  })
);

interface PoorQualityProps {
  quality: QualityScore | undefined;
  openModal: () => void;
}

export function PoorQuality({ quality, openModal }: PoorQualityProps) {
  const { nextPane } = useAppStateContext();
  const classes = useStyles();

  return (
    <Container>
      <div>
        <Grid item lg={5} className={classes.header}>
          <Typography variant="h1" gutterBottom className={classes.heading}>
            {quality === QualityScore.Suboptimal ? <WarningIcon /> : <ErrorIcon />}
            {quality === QualityScore.Suboptimal ? 'Μέτρια' : 'Προβληματική'} Ποιότητα
          </Typography>

          <Typography variant="body1" gutterBottom>
            Αυτό είναι το τελευταίο βημα! Η αναμενόμενη ποιότητα του βίντεο/ήχου σου είναι{' '}
            <strong>{quality === QualityScore.Suboptimal ? 'μέτρια' : 'κακή'} </strong>
            και η συνολική απόδοση {quality === QualityScore.Suboptimal ? 'ειναι μέτρια' : 'είναι κακή'}.
          </Typography>
        </Grid>

        <Grid item lg={5} className={classes.paperContainer}>
          <Paper className={classes.paper}>
            <Typography variant="body1">
              <strong>Συμβουλές για τη βελτίωση της ποιότητας και της απόδοσης της κλήσης:</strong>
            </Typography>

            <ul>
              <li>
                <Typography variant="body1">
                  Κλείσε αχρησιμοποίητες καρτέλες και εφαρμογές στον υπολογιστή ή συσκευή σου.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">Πλησίασε πιο κοντά στο router σου.</Typography>
              </li>
              <li>
                <Typography variant="body1">Κλείσε ή αποσυνδέσου απο το VPN.</Typography>
              </li>
              <li>
                <Typography variant="body1">Κάνε επανεκκίνηση του υπολογιστή ή της συσκευής σου.</Typography>
              </li>
            </ul>
          </Paper>

          <Button variant="outlined" onClick={openModal} className={classes.viewButton}>
            <ViewIcon />
            Δες λεπτομέριες για την ποιότητα σου
          </Button>
        </Grid>

        <Grid item lg={5} className={classes.confirmationButtons}>
          <Typography variant="body1" gutterBottom>
            <strong>Δοκίμασες όλες τις συμβουλές για να βελτιώσεις την ποιότητα;</strong>
          </Typography>

          <Button variant="contained" color="primary" onClick={nextPane} style={{ marginRight: '1.5em' }}>
            Ναι
          </Button>
          <Button color="primary" onClick={nextPane}>
            Παράλειψη
          </Button>
        </Grid>
      </div>
    </Container>
  );
}
