import { makeStyles, Button, Container, Grid, Typography, Theme, createStyles, Hidden } from '@material-ui/core';
import { ActivePane, useAppStateContext } from '../../AppStateProvider/AppStateProvider';
import { CheckMark } from '../../../icons/CheckMark';
import { DownloadIcon } from '../../../icons/DownloadIcon';
import { getQualityScore } from '../Quality/getQualityScore/getQualityScore';
import { QualityScore } from '../Quality/Quality';
import { SmallError } from '../../../icons/SmallError';
import SomeFailed from './SomeFailed.png';
import TestsPassed from './TestsPassed.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      float: 'left',
      [theme.breakpoints.down('md')]: {
        float: 'initial',
        paddingBottom: '1em',
      },
    },
    buttonContainer: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      gap: '1em',
      width: '100%',
    },
    resultsList: {
      float: 'right',
      [theme.breakpoints.down('md')]: {
        float: 'initial',
      },
    },
    resultContainer: {
      marginTop: '1.5em',
      '&:not(:last-child)': {
        paddingBottom: '1.5em',
        borderBottom: '0.1em solid #8891AA',
      },
      [theme.breakpoints.down('md')]: {
        '&:last-child': {
          paddingBottom: '1.5em',
        },
      },
    },
    iconContainer: {
      display: 'flex',
      '& svg': {
        margin: '0.2em 0.8em 0 0',
      },
    },
    downloadButton: {
      '& svg': {
        position: 'relative',
        left: '-5px',
      },
    },
    restartButton: {
      backgroundColor: '#FFFFFF',
      borderColor: '#8891AA',
    },
    illustration: {
      marginTop: '7em',
    },
    hardwareButton: {
      marginRight: '1.5em',
    },
    gutterBottom: {
      marginBottom: '1em',
      [theme.breakpoints.down('md')]: {
        marginBottom: '1.5em',
      },
    },
  })
);

export function Results() {
  const { state, downloadFinalTestResults, dispatch } = useAppStateContext();
  const { totalQualityScore } = getQualityScore(state.preflightTest.report, state.bitrateTest.report);
  const classes = useStyles();

  const testsPassed = totalQualityScore === QualityScore.Excellent || totalQualityScore === QualityScore.Good;
  const qualityScore = QualityScore[totalQualityScore].toLowerCase();
  var qualityScoreGr = qualityScore;

  if (qualityScore === 'excellent') {
    qualityScoreGr = 'εξαιρετική';
  } else if (qualityScore === 'good') {
    qualityScoreGr = 'καλή';
  } else if (qualityScore === 'suboptimal') {
    qualityScoreGr = 'μέτρια';
  } else if (qualityScore === 'poor') {
    qualityScoreGr = 'κακή';
  }

  return (
    <>
      <Container>
        <div>
          <Grid item lg={5} className={classes.header}>
            <Typography variant="h1" gutterBottom>
              {testsPassed ? 'Όλα τα τέστ πέρασαν!' : 'Κάποια τέστ απέτυχαν'}
            </Typography>

            {testsPassed ? (
              <Typography variant="body1" gutterBottom>
                Από τα αποτελέσματα των τεστ, το βίντεό σου θα πρέπει να λειτουργεί. Εάν εξακολουθείς να αντιμετωπίζεις
                προβλήματα, κάνε λήψη της αναφοράς και στείλε τη στην ομάδα του ClicktoTherapy.
              </Typography>
            ) : (
              <Typography variant="body1" gutterBottom>
                <strong>Ένα απο τα τρία </strong>
                τεστ απέτυχαν – χρησιμοποίησε αυτήν τη λίστα για να επιλύσεις κοινά προβλήματα βίντεο και να
                επανεκκινήσεις τη δοκιμή. Εαν δεν μπορείς εύκολα να λύσεις τα προβλήματα αυτά, κάνε λήψη της αναφοράς
                και στείλε τη στην ομάδα του ClicktoTherapy.
              </Typography>
            )}

            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.downloadButton}
                onClick={downloadFinalTestResults}
              >
                <DownloadIcon />
                Κάνε λήψη των αποτελεσμάτων
              </Button>
              <Button variant="outlined" className={classes.restartButton} onClick={() => window.location.reload()}>
                Επανεκκίνιση των ελέγχων.
              </Button>
            </div>

            <Hidden mdDown>
              <img
                src={testsPassed ? TestsPassed : SomeFailed}
                alt={testsPassed ? 'Success' : 'Some Failed'}
                className={classes.illustration}
              />
            </Hidden>
          </Grid>

          <Grid item lg={5} className={classes.resultsList}>
            <div className={classes.resultContainer}>
              <div className={classes.iconContainer}>
                <CheckMark />
                <Typography variant="h3" className={classes.gutterBottom}>
                  Συσκευή &amp; Ρύθμιση δικτύου
                </Typography>
              </div>
              <Typography variant="body1" className={classes.gutterBottom}>
                Ο ήχος και το βίντεο επιβεβαιώθηκαν ότι τρέχουν επιτυχώς στη συσκευή σου.
              </Typography>

              <div className={classes.buttonContainer}>
                <Button
                  variant="outlined"
                  onClick={() => dispatch({ type: 'set-active-pane', newActivePane: ActivePane.CameraTest })}
                >
                  Έλεγξε τη συσκευή
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => dispatch({ type: 'set-active-pane', newActivePane: ActivePane.BrowserTest })}
                >
                  Έλεγξε το πρόγραμμα περιήγησης
                </Button>
              </div>
            </div>

            <div className={classes.resultContainer}>
              <div className={classes.iconContainer}>
                <CheckMark />
                <Typography variant="h3" className={classes.gutterBottom}>
                  Συνδεσιμότητα
                </Typography>
              </div>
              <Typography variant="body1" className={classes.gutterBottom}>
                Όλες οι συνδέσεις λειτουργούν με επιτυχία.
              </Typography>
              <Button
                variant="outlined"
                onClick={() => dispatch({ type: 'set-active-pane', newActivePane: ActivePane.Connectivity })}
              >
                Δες τη Συνδεσιμότητα σου.
              </Button>
            </div>

            <div className={classes.resultContainer}>
              <div className={classes.iconContainer}>
                {testsPassed ? <CheckMark /> : <SmallError />}
                <Typography variant="h3" className={classes.gutterBottom}>
                  Ποιότητα &amp; Επίδοση
                </Typography>
              </div>

              {testsPassed ? (
                <Typography variant="body1" className={classes.gutterBottom}>
                  Υπέροχα! Η αναμενόμενη ποιότητα κλήσης σου είναι <strong>{qualityScoreGr}</strong> και η συνολική
                  απόδοση είναι
                  {qualityScore === 'εξαιρετική' ? ' Καλή' : ' ΟΚ'}.
                </Typography>
              ) : (
                <Typography variant="body1" className={classes.gutterBottom}>
                  Η συνολική σου βαθμολογία είναι <strong>{qualityScoreGr}</strong> πράγμα που σημαίνει ότι η σύνδεσή
                  σου δεν είναι αρκετά καλή για να εκτελείται σωστά το βίντεο. Δοκίμασε αυτές τις συμβουλές και
                  επανέλαβε τη δοκιμή.
                </Typography>
              )}

              <Button
                variant="outlined"
                onClick={() => dispatch({ type: 'set-active-pane', newActivePane: ActivePane.Quality })}
              >
                Έλεγξε την απόδοση
              </Button>
            </div>
          </Grid>
        </div>
      </Container>
    </>
  );
}
