import { useAppStateContext } from '../../../AppStateProvider/AppStateProvider';
import { createStyles, makeStyles, Button, Container, Grid, Typography, Paper } from '@material-ui/core';
import { DownloadIcon } from '../../../../icons/DownloadIcon';
import { ErrorIcon } from '../../../../icons/ErrorIcon';
import { ViewIcon } from '../../../../icons/ViewIcon';

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      float: 'left',
      [theme.breakpoints.down('md')]: {
        float: 'initial',
      },
    },
    heading: {
      position: 'relative',
      marginTop: '20px',
    },
    paperContainer: {
      float: 'right',
      marginRight: '1em',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        float: 'initial',
        justifyContent: 'center',
        margin: '0 0 2.5em 0',
      },
    },
    paper: {
      padding: '1.5em',
      borderRadius: '8px',
      maxWidth: '400px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    downloadButton: {
      clear: 'left',
      marginTop: '2em',
      '& svg': {
        position: 'relative',
        left: '-5px',
      },
      [theme.breakpoints.down('md')]: {
        clear: 'initial',
        marginBottom: '2em',
      },
    },
    viewButton: {
      marginTop: '2em',
      '& svg': {
        position: 'relative',
        left: '-5px',
      },
    },
  })
);

interface ConnectionFailedProps {
  openModal: () => void;
}

export function ConnectionFailed({ openModal }: ConnectionFailedProps) {
  const classes = useStyles();
  const { downloadFinalTestResults } = useAppStateContext();

  return (
    <>
      <Container>
        <div>
          <Grid item lg={5} className={classes.header}>
            <Typography variant="h1" gutterBottom className={classes.heading}>
              <ErrorIcon />
              Η σύνδεση απέτυχε.
            </Typography>

            <Typography variant="body1" gutterBottom>
             Είναι πιθανό να βρίσκεσαι πίσω από ένα τείχος προστασίας. Ενημέρωσε τον διαχειριστή του δικτύου σου για να σε βοηθήσει.
            </Typography>
          </Grid>

          <Grid item lg={5} className={classes.paperContainer}>
            <Paper className={classes.paper}>
              <Typography variant="body1" gutterBottom>
              Για να πραγματοποιήσεις μια βιντεοκλήση, η σύνδεση σου πρέπει να μπορεί να επικοινωνεί με το δίκτυο του ClicktoTherapy.
              Για περαιτέρω Πληροφορίες επικοινώνησε με το support@clicktotherapy.com
              </Typography>

              <Typography variant="body1">
                Μάθετε{' '}
                <a href="https://www.twilio.com/docs/video/ip-addresses" target="_blank" rel="noopener noreferrer">
                  πώς να ρυθμίσεις σωστά το τείχος προστασίας σου
                </a>
                .
              </Typography>
            </Paper>
            <Button variant="outlined" onClick={openModal} className={classes.viewButton}>
              <ViewIcon />
              Δες λεπτομέρειες για τη σύνδεση σου
            </Button>
          </Grid>

          <Grid item lg={5}>
            <Typography variant="body1">
             Κατέβασε τα αποτελέσματα, στείλε τα στον διαχειριστή του δικτύου σου ή στο support@clicktotherapy.com και μετά τις απαιτούμενες ενέργειες μπορείς να δοκιμάσεις ξανά.
            </Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={downloadFinalTestResults}
              className={classes.downloadButton}
            >
              <DownloadIcon />
              Κατέβασε τα αποτελέσματα
            </Button>
          </Grid>
        </div>
      </Container>
    </>
  );
}
